import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '../translate/translate.module';
import { UserService } from '../services/user.service';
import { TranslatePipe } from '../translate/pipes/translate.pipe';
import { LoadingSpinnerComponent } from '../components/loading-spinner/loading-spinner.component';
import { LoggerService } from '../services/logger.service';

@Component({
  selector: 'app-neptun-modal',
  templateUrl: './neptun-modal.page.html',
  styleUrls: ['./neptun-modal.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, LoadingSpinnerComponent, TranslateModule]
})
export class NeptunModalPage implements OnInit {
  @Input() labelText: string = '';
  @Input() path: any;
  @Input() size: 'small' | 'medium' = 'medium';
  loading: boolean = false;
  neptunCode: string = '';

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private tp: TranslatePipe,
    private logger: LoggerService
  ) { }

  ngOnInit() { }

  ionViewWillEnter() {
    if (!this.labelText) {
      this.labelText = this.tp.transform('ADD_EDU_ID_TO_JOIN_CLASS');
    }
  }

  async changeModal(type: 'confirm' | 'dismiss') {
    if (type === 'dismiss') {
      this.modalController.dismiss(null, 'cancel');
    } else {
      this.loading = true;
      let hashedNeptunCode = await this.logger.hashString(this.neptunCode);
      let res = await this.userService.addNeptunCodeIfValid(hashedNeptunCode, this.path);
      if (res?.info === 'ADDED_SUCCESSFULLY') {
        this.modalController.dismiss(hashedNeptunCode, res.info);
      } else {
        this.logger.addLogItem('NEPTUN_CODE_SET_ERROR', { error: res }, null, 'error');
        this.loading = false;
      }
    }
  }
}